import { css } from '@emotion/core'
import { navigate } from 'gatsby'
import { clone, find, flatten, mapObjIndexed, pipe, values } from 'ramda'
import React from 'react'
import { Subscribe } from 'unstated'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

import SaveIcon from '@material-ui/icons/Check'

import Layout from '../../components/layout'

import SavingsContainer from '../../containers/SavingsContainer'

import {
  findYearEndPeriod,
  getPeriodDisplayText,
  getPreviousPeriod,
  getValidPeriodMonths,
} from '../../util'
import withRoot from '../../withRoot'

const getDefaultPeriodData = pipe(
  mapObjIndexed((lineItems, type) =>
    lineItems.map(lineItem => ({ type, ...lineItem, value: 0 }))
  ),
  values,
  flatten
)

class CreatePeriodEditor extends React.Component {
  state = { copyFromPrevious: false }

  static styles = {
    formControl: css`
      margin-top: 12px;
      min-width: 120px;
    `,
  }

  componentDidMount() {
    this.props.onPeriodChange(this.props.period)
  }

  render() {
    const alreadyHasEntireYear = !!findYearEndPeriod(this.props.period.year,
      this.props.periods
    )

    const monthsToChooseFrom = getValidPeriodMonths(
      this.props.period.year,
      this.props.periods
    )

    const previousPeriod = getPreviousPeriod(
      this.props.period,
      this.props.periods
    )
    const previousPeriodText =
      this.state.copyFromPrevious && previousPeriod
        ? `Copy data from ${getPeriodDisplayText(
            previousPeriod.year,
            previousPeriod.month
          )}`
        : !previousPeriod
        ? 'No previous period to copy from'
        : 'Copy data from previous period'

    return (
      <>
        <Paper css={{ padding: 16 }}>
          <Grid container>
            <Grid item xs={6}>
              <FormControl css={CreatePeriodEditor.styles.formControl}>
                <InputLabel shrink htmlFor="period-month">
                  Month
                </InputLabel>
                <Select
                  data-testid="period-month-select"
                  value={this.props.period.month}
                  onChange={this.handlePeriodTimeChange}
                  inputProps={{ name: 'month', id: 'period-month' }}
                  placeholder="Month"
                  displayEmpty
                >
                  {!alreadyHasEntireYear && (
                    <MenuItem value={undefined} data-testid="period-month-all">
                      <em>Entire Year</em>
                    </MenuItem>
                  )}
                  {monthsToChooseFrom.map(([month, name]) => (
                    <MenuItem
                      key={month}
                      value={month}
                      data-testid="period-month"
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl css={CreatePeriodEditor.styles.formControl}>
                <InputLabel shrink htmlFor="period-year">
                  Year
                </InputLabel>
                <Input
                  type="number"
                  value={this.props.period.year}
                  onChange={this.handlePeriodTimeChange}
                  inputProps={{
                    'data-testid': 'period-year',
                    name: 'year',
                    id: 'period-year',
                  }}
                  placeholder="Year"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {this.props.period.month === undefined && (
                <Typography
                  css={{ marginTop: 16 }}
                  variant="caption"
                  color="textSecondary"
                >
                  Filling in amounts for the entire year will override your YTD
                  savings calculation
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                css={{ marginTop: 16 }}
                control={
                  <Switch
                    disabled={!previousPeriod}
                    checked={this.state.copyFromPrevious}
                    onChange={this.handleCopyFromPreviousChange}
                  />
                }
                label={previousPeriodText}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    )
  }

  handleCopyFromPreviousChange = event => {
    if (event.target.checked) {
      this.setState({ copyFromPrevious: true }, () => {
        this.props.onPeriodChange({
          ...this.props.period,
          data: this.getDataFromPreviousPeriod(),
        })
      })
    } else {
      this.setState({ copyFromPrevious: false }, () => {
        this.props.onPeriodChange({
          ...this.props.period,
          data: getDefaultPeriodData(this.props.defaultSavingsComponents),
        })
      })
    }
  }

  getDataFromPreviousPeriod = (currentPeriod = this.props.period) => {
    if (this.state.copyFromPrevious) {
      const previousPeriod = getPreviousPeriod(
        currentPeriod,
        this.props.periods
      )

      if (previousPeriod) {
        return clone(previousPeriod.data)
      }
    }

    return getDefaultPeriodData(this.props.defaultSavingsComponents)
  }

  handlePeriodTimeChange = event => {
    const { name: prop, value } = event.target
    const numberValue = parseInt(value, 10)

    if (isNaN(numberValue)) {
      const updatedPeriod = {
        ...this.props.period,
        [prop]: undefined,
      }

      return this.props.onPeriodChange({
        ...updatedPeriod,
        data: this.getDataFromPreviousPeriod(updatedPeriod),
      })
    }

    switch (prop) {
      case 'year': {
        const validMonthsInYear = getValidPeriodMonths(
          numberValue,
          this.props.periods
        )
        const currentMonth = this.props.period.month

        if (
          currentMonth !== undefined &&
          !find(([month]) => month === currentMonth, validMonthsInYear)
        ) {
          const updatedPeriod = {
            ...this.props.period,
            month: validMonthsInYear[0][0],
            year: numberValue,
          }
          return this.props.onPeriodChange({
            ...updatedPeriod,
            data: this.getDataFromPreviousPeriod(updatedPeriod),
          })
        }
        break
      }
      default:
        break
    }

    const updatedPeriod = {
      ...this.props.period,
      [prop]: numberValue,
    }
    return this.props.onPeriodChange({
      ...updatedPeriod,
      data: this.getDataFromPreviousPeriod(updatedPeriod),
    })
  }
}
CreatePeriodEditor.defaultProps = {
  period: null,
  periods: [],
  defaultSavingsComponents: {},
  onPeriodChange: period => {},
}

class CreatePeriodPage extends React.Component {
  state = { period: null }

  render() {
    return (
      <Subscribe to={[SavingsContainer]}>
        {(savingsData) => {
          const getInitialPeriod = (defaultSavingsComponents, periods = []) => {
            const year = new Date().getFullYear()
            const months = getValidPeriodMonths(year, periods)

            return {
              year,
              month: months.length ? months[0][0] : undefined,
              data: getDefaultPeriodData(defaultSavingsComponents),
            }
          }
          const period =
            this.state.period ||
            getInitialPeriod(
              savingsData.state.defaultComponents,
              savingsData.state.periods
            )

          return (
            <Layout
              upwards="/"
              title="Choose time period"
              pathname={this.props.location.pathname}
              appBarContent={
                <IconButton
                  color="inherit"
                  aria-label="Add new period"
                  onClick={this.handleSave(savingsData.setPeriod)}
                  data-testid="btn-save"
                >
                  <SaveIcon />
                </IconButton>
              }
            >
              <CreatePeriodEditor
                period={period}
                periods={savingsData.state.periods}
                defaultSavingsComponents={savingsData.state.defaultComponents}
                onPeriodChange={this.handlePeriodChange}
              />
            </Layout>
          )
        }}
      </Subscribe>
    )
  }

  handleSave = setPeriod => () => {
    if (setPeriod) {
      const { period } = this.state

      if (period) {
        setPeriod(period.year, period.month, period, () => {
          navigate('/period/edit', {
            state: { year: period.year, month: period.month },
            replace: true,
          })
        })
      }
    }
  }

  handlePeriodChange = period => {
    this.setState({ period })
  }
}

export default withRoot(CreatePeriodPage)
